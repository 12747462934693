<template>
  <span class="tri-wrapper" @click="triChange">
    <i
      class="el-icon-sort-down"
      :class="{ 'tri-fleche-active': isTriCol && triSens == 'ascending' }"
    ></i>
    <i
      class="el-icon-sort-up"
      :class="{ 'tri-fleche-active': isTriCol && triSens == 'descending' }"
    ></i>
    &nbsp;
  </span>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    colkey: String,
  },
  methods: {
    triChange() {
      this.$store.commit("comp/triChange", this.colkey);
      this.$store.commit("comp/setItems");
    },
  },
  computed: {
    triSens() {
      return this.$store.state.comp.triSens;
    },
    triCol() {
      return this.$store.state.comp.triCol;
    },
    isTriCol() {
      if (this.triCol == this.colkey) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
