<template>
  <span>
    <span class="collabel">{{ collabel }}</span>
    <app-rowtri :colkey="colkey"> </app-rowtri>
    <app-rowfiltre :colkey="colkey" />
  </span>
</template>

<script>
import AppRowtri from "@/components/rh/ui/table/AppRowtri";
import AppRowfiltre from "@/components/rh/ui/table/AppRowfiltre";
export default {
  components: { "app-rowtri": AppRowtri, "app-rowfiltre": AppRowfiltre },

  data() {
    return {};
  },
  props: {
    collabel: String,
    colkey: String,
  },
};
</script>
