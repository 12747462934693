<template>
  <div class="table-filtre-autocompl">
    <el-autocomplete
      v-model="searchText"
      :fetch-suggestions="searchSugg"
      prefix-icon="el-icon-search"
      placeholder="Saisir votre recheche ici"
      select-when-unmatched
      autofocus
      clearable
      v-show="isTxtValue"
      :disabled="colisfiltered"
    />
    <el-checkbox
      v-model="nullChecked"
      v-show="isNullValue"
      :disabled="colisfiltered"
      >NULL</el-checkbox
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      nullChecked: false,
    };
  },
  props: {
    colkey: String,
    colisfiltered: Boolean,
    coltypefiltre: String,
    foptions: Array,
  },
  mounted() {
    //if (this.colisfiltered && !this.searchText) {
    if (this.colisfiltered) {
      /* si colonne filtrée -> rempli les champs du filtre */
      // check la case null si valeurs null non filtrées
      this.nullChecked = this.isNullValue;
      // récupère le string saisi pour filtrer la colonne
      this.searchText = this.$store.state.comp.itemsFiltre.filter(
        (f) => f.col == this.colkey
      )[0].valf;
    }
  },
  computed: {
    isNullValue() {
      // définit si montre la checkbox pour les valeurs null
      return this.foptions.filter((v) => !v).length > 0;
    },
    isTxtValue() {
      // définit si montre le champ pour saisir du text
      return this.foptions.filter((v) => v).length > 0;
    },
  },
  methods: {
    searchSugg(queryString, cb) {
      const vals = this.foptions
        .filter((v) => v)
        .map((v) => {
          return { value: v };
        });
      const results = queryString
        ? vals.filter((val) => this.texteFiltre(val.value, queryString))
        : vals;
      // call callback function to return suggestions
      cb(results);
    },
    getTextFiltre() {
      if (this.searchText || this.nullChecked) {
        // mets à jour filterActu dans Vuex avec le nom de la colonne 'colkey', le text recherché et si recherche valeur null
        this.$store.commit({
          type: "comp/filtreChange",
          col: this.colkey,
          typef: this.coltypefiltre,
          valf: this.searchText,
          nullchecked: this.nullChecked,
        });
      }
    },
    clearTextFiltre() {
      this.searchText = "";
      this.nullChecked = false;
      this.$store.commit({
        type: "comp/filtreChange",
        col: this.colkey,
        typef: this.coltypefiltre,
        valf: this.searchText,
        nullchecked: this.nullChecked,
      });
    },
    texteFiltre(val, txt) {
      if (txt && val) {
        // si une valeur saisie dans le champ de recherche
        // true si le string recheché est n'importe où dans le texte et quelque soit la case
        return (
          val.toString().toLowerCase().indexOf(txt.toString().toLowerCase()) !=
          -1
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    colisfiltered: {
      handler() {
        // si pas filtré (bouton 'effacer tous les filtres')=> efface le texte du filtre
        if (!this.colisfiltered) {
          this.searchText = "";
          this.nullChecked = false;
        }
      },
      // pour lancer le watch à la création du composent
      immediate: true,
    },
  },
};
</script>
