<template>
  <div class="table-filtre-datepicker">
    <el-date-picker
      v-model="dateVals"
      type="monthrange"
      align="right"
      unlink-panels
      range-separator="-"
      start-placeholder="Mois début"
      end-placeholder="Mois fin"
      :picker-options="pickerOptions"
      :clearable="false"
      :default-value="new Date()"
      v-show="isDateValue"
      :disabled="colisfiltered"
    />
    <el-checkbox
      v-model="nullChecked"
      v-show="isNullValue"
      :disabled="colisfiltered"
      >NULL</el-checkbox
    >
  </div>
</template>

<script>
import { toLocaleDateMois } from "@/fonctions";
export default {
  data() {
    // 1ère et dernière valeur et pas de null => array avec 0 ou 2 valeurs
    const valsMinMax = this.foptions.filter((v) => v);
    return {
      dateVals: [],
      nullChecked: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "- 2 ans",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "- 1 an",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "- 6 mois",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "- 3 mois",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: ">> ce mois",
            onClick(picker) {
              const cemoisStr = toLocaleDateMois(new Date());
              const valMin = valsMinMax[0];
              const start = valMin
                ? valMin < cemoisStr
                  ? valMin
                  : cemoisStr
                : cemoisStr;
              picker.$emit("pick", [start, cemoisStr]);
            },
          },
          {
            text: "ce mois",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "ce mois >>",
            onClick(picker) {
              const cemoisStr = toLocaleDateMois(new Date());
              const valMax = valsMinMax[1];
              const end = valMax
                ? valMax > cemoisStr
                  ? valMax
                  : cemoisStr
                : cemoisStr;
              picker.$emit("pick", [cemoisStr, end]);
            },
          },
          {
            text: "+ 3 mois",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(end.getMonth() + 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "+ 6 mois",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(end.getMonth() + 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "+ 1 an",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(end.getMonth() + 12);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "+ 2 ans",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setMonth(end.getMonth() + 24);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  props: {
    colkey: String,
    colisfiltered: Boolean,
    coltypefiltre: String,
    foptions: Array,
  },
  mounted() {
    if (this.colisfiltered && this.dateVals.length == 0) {
      // check la case null si valeurs null non filtrées
      this.nullChecked = this.isNullValue;
      // si colonne filtrée, récupère les valeurs du filtre
      this.dateVals = this.$store.state.comp.itemsFiltre.filter(
        (f) => f.col == this.colkey
      )[0].valf;
    }
  },
  computed: {
    isNullValue() {
      // définit si montre la checkbox pour les valeurs null
      return this.foptions.filter((v) => !v).length > 0;
    },
    isDateValue() {
      // définit si montre le datepicker
      return this.foptions.filter((v) => v).length > 0;
    },
  },
  methods: {
    getDateFiltre() {
      if (this.dateVals.length == 2 || this.nullChecked) {
        // convertit les date en string au format yyyy-mm
        let debutFin = this.dateVals.map((d) => toLocaleDateMois(d));
        // mets à jour filterActu dans Vuex
        this.$store.commit({
          type: "comp/filtreChange",
          col: this.colkey,
          typef: this.coltypefiltre,
          valf: debutFin,
          nullchecked: this.nullChecked,
        });
      }
    },
    clearDateFiltre() {
      this.dateVals = [];
      this.nullChecked = false;
      this.$store.commit({
        type: "comp/filtreChange",
        col: this.colkey,
        typef: this.coltypefiltre,
        valf: this.dateVals,
        nullchecked: this.nullChecked,
      });
    },
  },
  watch: {
    colisfiltered: {
      handler() {
        // si pas filtré, efface les dates dans le picker
        if (!this.colisfiltered) {
          this.dateVals = [];
          this.nullChecked = false;
        }
      },
      // pour lancer le watch à la création du composent
      immediate: true,
    },
  },
};
</script>
