<template>
  <div>
    <app-tabletools
      :createroute="formroutes.create"
      :showclearallfilters="showClearAllFilters"
      :tablenom="tablenom"
      :ismeddetail="ismeddetail"
    />
    <el-table
      class="app-table"
      :data="itemsTableDisplay"
      style="width: 100%"
      stripe
      border
      size="medium"
      header-row-class-name="table-entete"
      v-loading="loadstatus == 1 || usertablesprefsLoadStatus == 1"
      element-loading-text="Chargement des données ..."
    >
      <template v-slot:empty>{{ txtLoadEmpty }}</template>
      <el-table-column fixed="left" label="" width="50" v-slot="{ row }">
        <el-tooltip content="Ouvrir / Editer">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit-outline"
            circle
            :disabled="row.noedit"
            @click="goToItem(row)"
          ></el-button>
        </el-tooltip>
      </el-table-column>
      <el-table-column
        v-for="col in colsTableDisplay"
        :key="col.prop"
        :prop="col.prop"
        :label="col.label"
        :label-class-name="{ highlight: col.isFiltered }"
        :column-key="col.prop"
        :resizable="true"
        :show-overflow-tooltip="true"
      >
        <template v-slot="{ row }">
          <!-- Colonne avec lien interne -->
          <el-button
            v-if="col.lien"
            type="text"
            v-text="stringToObjKey(col.prop, row)"
            @click="goToLien(row, col.lienroute)"
          >
          </el-button>
          <!-- Colonne avec lien vers doc -->
          <div v-else-if="col.liendoc" class="col-icon">
            <a
              v-if="stringToObjKey(col.prop, row)"
              target="_blank"
              :href="docUrl(col, row)"
            >
              <i class="el-icon-view" />
            </a>
          </div>
          <!-- Colonne avec email -->
          <div v-else-if="col.email">
            <a
              v-if="stringToObjKey(col.prop, row)"
              :href="'mailto:' + stringToObjKey(col.prop, row)"
            >
              {{ stringToObjKey(col.prop, row) }}
            </a>
          </div>
          <!-- Colonne true/false -->
          <div v-else-if="col.bool" class="col-icon">
            <i v-if="stringToObjKey(col.prop, row)" class="el-icon-check" />
            <i v-else class="el-icon-close" />
          </div>
          <span v-else v-text="stringToObjKey(col.prop, row)"></span>
        </template>
        <app-tableheader slot="header" :collabel="col.label" :colkey="col.prop">
        </app-tableheader>
      </el-table-column>
      <el-table-column fixed="right" label="" width="50" v-slot="{ row }">
        <el-tooltip content="Supprimer">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            :disabled="row.nodelete"
            @click="DeleteItem(row.id)"
          ></el-button>
        </el-tooltip>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChange"
      @current-change="pageChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="linePage"
      :pager-count="10"
      layout="prev,pager,next,sizes,total,jumper"
      :total="itemsTableVisibleCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import { objGetNested, toRouterParams } from "@/fonctions";
import AppTableheader from "@/components/rh/ui/table/AppTableheader";
import AppTabletools from "@/components/rh/ui/table/AppTabletools";
export default {
  components: {
    "app-tableheader": AppTableheader,
    "app-tabletools": AppTabletools,
  },

  data() {
    return {
      currentPage: 1,
      txtLoadEmpty: "",
      showClearAllFilters: false,
      userPrefsSet: false,
    };
  },
  props: {
    tablenom: String,
    cols: Array,
    items: Array,
    loadstatus: Number,
    deletestatus: Number,
    formroutes: Object,
    actions: Object,
    ismeddetail: Boolean,
  },
  created() {
    // évite de récupérer les cols de la table précédente (problème affichage ordre des colonnes)
    this.$store.commit("comp/resetCols");
  },
  mounted() {
    this.$store.commit("comp/setIsMounted", false);
    // Reset toutes les prefs de l'user
    this.$store.commit("comp/clearAllPrefs");
    // récupère le nom de la table dans vuex
    this.$store.commit("comp/setTableNom", this.tablenom);
    // chargement des items, cols et userprefs à l'ouverture de la table
    // la 1ère table est chargée sur le watch de allLoaded
    this.onAllLoaded();
  },
  computed: {
    itemsTable: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.items;
      },
      set(itemsTable) {
        this.$store.commit("comp/setItems", itemsTable);
      },
    },
    colsTable: {
      get() {
        return this.$store.state.comp.cols;
      },
      set(colsTable) {
        this.$store.commit("comp/setCols", colsTable);
      },
    },
    // nombre de lignes par page
    linePage: {
      get() {
        const lp = this.$store.state.comp.linePage;
        const maxSP = Math.max(...this.pageSizes);
        return lp > maxSP ? maxSP : lp;
      },
      set(linePage) {
        this.$store.commit("comp/setLinePage", linePage);
      },
    },
    // array des options du nombre de lignes par page, dépend du nbre de lignes affichées
    pageSizes() {
      const totls = this.itemsTable.filter((i) => i.visible).length;
      const opts = [5, 10, 20, 30, 40, 50, 100];
      return opts.filter((o) =>
        totls <= 5 ? o == 5 : totls <= 50 ? o <= Math.ceil(totls / 10) * 10 : o
      );
    },
    // Nombre de lignes visibles
    itemsTableVisibleCount() {
      return this.itemsTable.filter((i) => i.visible).length;
    },
    // affiche les données sur la page
    itemsTableDisplay() {
      return (
        this.itemsTable
          // données filtrées
          .filter((i) => i.visible)
          // données retournées avec pagination
          .slice(
            (this.currentPage - 1) * this.linePage,
            this.currentPage * this.linePage
          )
      );
    },
    // affichage des colonnes
    colsTableDisplay() {
      return this.colsTable.filter((c) => c.visible);
    },
    usertablesprefsLoadStatus() {
      return this.$store.state.comp.usertablesprefsLoadStatus;
    },
    allLoaded() {
      return this.usertablesprefsLoadStatus == 2 && this.loadstatus == 2;
    },
  },
  methods: {
    // pour récupérer le contenu des colonnes
    stringToObjKey(col, row) {
      return objGetNested(row, col.split("."));
    },
    // Pagination
    sizeChange(val) {
      this.linePage = val;
    },
    pageChange(val) {
      this.currentPage = val;
    },
    goToLien(row, lienroute) {
      var routeNom = lienroute.route;
      var para = lienroute.params;
      this.$router.push({ name: routeNom, params: toRouterParams(para, row) });
      // si table dans détail médecin, recharge les données du médecin pour
      // afficher le résultat dans le "détail médecin" du médecin correspondant au lien ...
      if (para.idmed) {
        this.$store.dispatch("medl/loadMedecin", this.$route.params.idmed);
      }
    },
    docUrl(col, row) {
      return (
        //process.env.VUE_APP_API_URL + (redirigé vers l'url de l'API dans route.js)
        col.docfolder +
        objGetNested(row, col.prop.split(".")) +
        "?rnd=" +
        Math.random()
      );
    },
    goToItem(row) {
      var routeNom = this.formroutes.edit.route;
      var para = this.formroutes.edit.params;
      this.$router.push({ name: routeNom, params: toRouterParams(para, row) });
    },
    DeleteItem(rowId) {
      this.$confirm(
        "Vous allez supprimer cet enregistrement ainsi que <b>toutes</b> les données qui lui sont liées !",
        "Attention !",
        {
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        this.$store.dispatch(this.actions.delete, rowId);
      });
    },
    onAllLoaded() {
      if (this.allLoaded) {
        this.$store.commit("comp/setUserCurrentTablePrefs");
        // importe les lignes dans Vuex
        this.itemsTable = this.items;
        // importe les colonnes dans Vuex
        this.colsTable = this.cols;
        // pour lancer les maj des userPrefs sur changements (watch items, cols, lienpage)
        this.userPrefsSet = true;
        // si aucune ligne visible efface tous les filtres
        if (this.itemsTable.filter((i) => i.visible).length == 0) {
          this.$store.commit("comp/clearAllFilters");
          this.$store.commit("comp/setItems");
          this.$store.commit("comp/setCols");
        }
      }
    },
  },
  watch: {
    loadstatus() {
      if (this.loadstatus == 2) {
        // Le texte qui s'affiche si aucune données n'est chargée
        this.txtLoadEmpty == "Aucune donnée";
      } else if (this.loadstatus == 3) {
        this.txtLoadEmpty = "Echec du chargement des données !";
      } else {
        this.txtLoadEmpty = "";
      }
    },
    deletestatus() {
      // gère les messages lors de la suppression d'un enregistrement
      if (this.deletestatus == 2) {
        // message succès
        this.$message({
          message: "L'enregistrement a été supprimé !",
          type: "warning",
        });
      } else if (this.deletestatus == 3) {
        // message échec
        this.$message.error({
          message: "Echec de la suppression de l'enregistrement !",
        });
      }
    },
    allLoaded() {
      // chargement des items, des cols et des userprefs pour la première table (au chargement de l'app)
      this.onAllLoaded();
    },
    colsTable: {
      deep: true,
      handler() {
        // maj les user pref sur changement dans les colonnes
        if (this.userPrefsSet) {
          this.$store.dispatch("comp/majUserTablesPrefs");
        }
      },
    },
    itemsTable: {
      deep: true,
      handler() {
        // maj les user pref sur changement dans les items affichés
        if (this.userPrefsSet) {
          this.$store.dispatch("comp/majUserTablesPrefs");
        }
        // détermine si affiche le bouton pour effacer tous les filtres sur les lignes dans AppTabletools.vue
        // aussi efface l'affiche de tous les filtres à leur ouverture si !showClearAllFilters
        this.showClearAllFilters = this.itemsTable
          .map((i) => i.visible)
          .includes(false);
      },
    },
    linePage() {
      // maj les user pref sur changement dans les items affichés
      if (this.userPrefsSet) {
        this.$store.dispatch("comp/majUserTablesPrefs");
      }
    },
    itemsTableVisibleCount(newVal, oldVal) {
      // si le filtre des lignes change => revient sur la 1ère page
      if (newVal != oldVal) {
        this.currentPage = 1;
      }
    },
  },
};
</script>
