<template>
  <div class="table-filtre-checkbox">
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
    >
      Tout sélectionner
    </el-checkbox>
    <el-divider id="div-checkbox" />
    <el-checkbox-group v-model="checkList" @change="handleCheckedChange">
      <el-checkbox
        v-for="item in foptions"
        :key="item"
        :label="item"
        style="display: block"
      >
        <i
          v-if="coltypefiltre == 'checkbool'"
          :class="item ? 'el-icon-check' : 'el-icon-close'"
        />
        <span v-else>
          {{ item == null ? "NULL" : item }}
        </span>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkList: [],
      checkAll: false,
      isIndeterminate: false,
    };
  },
  props: {
    colkey: String,
    colisfiltered: Boolean,
    coltypefiltre: String,
    foptions: Array,
  },
  methods: {
    getCheckFiltre() {
      // appelée depuis le comp AppRowfiltre: bouton "Appliquer"
      this.$store.commit({
        type: "comp/filtreChange",
        col: this.colkey,
        typef: this.coltypefiltre,
        valf: this.checkList,
      });
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    clearCheckFiltre() {
      // appelée depuis le comp AppRowfiltre: bouton "Effacer"
      this.checkList = [];
      this.checkAll = false;
      this.isIndeterminate = false;
      this.$store.commit({
        type: "comp/filtreChange",
        col: this.colkey,
        typef: this.coltypefiltre,
        valf: this.checkList,
      });
    },
    // gère la box 'tout sélectionner'
    handleCheckAllChange(val) {
      this.checkList = val ? this.foptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(val) {
      const checkedCount = val.length;
      this.checkAll = checkedCount === this.foptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.foptions.length;
    },
  },
  watch: {
    colisfiltered: {
      handler() {
        // si filtré coche tout autrement décoche tout
        if (this.colisfiltered) {
          // si filtré => coche tout
          this.checkList = this.foptions;
          this.checkAll = true;
          this.isIndeterminate = false;
        } else {
          // si pas de filtre => décoche tout
          this.checkList = [];
          this.checkAll = false;
          this.isIndeterminate = false;
        }
      },
      // pour lancer le watch à la création du composent
      immediate: true,
    },
  },
};
</script>
