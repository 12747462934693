<template>
  <div id="table-tools">
    <!-- bouton filtre des colonnes -->
    <el-dialog
      title="Filtrer les colonnes"
      :visible.sync="dialogFiltreColOpen"
      width="300px"
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >
        Tout sélectionner
      </el-checkbox>
      <el-divider id="div-checkbox" />
      <el-checkbox
        v-for="col in colsTable"
        v-show="!colIsId(col.prop)"
        :key="col.prop"
        :label="col.label"
        v-model="col.visible"
        style="display: block"
      >
      </el-checkbox>
    </el-dialog>
    <!-- Bouton ajouter un item -->
    <div>
      <el-button type="primary" size="medium" plain @click="openFormCreate"
        ><i class="el-icon-plus" /> Ajouter</el-button
      >
    </div>
    <!-- Bouton filtrer les colonnes -->
    <div>
      <el-button
        type="primary"
        size="medium"
        plain
        @click="dialogFiltreColOpen = true"
        ><i class="el-icon-view" /> Colonnes</el-button
      >
    </div>
    <!-- Bouton effacer tous les filtres sur les lignes -->
    <div v-show="showclearallfilters">
      <el-button type="warning" size="medium" plain @click="clearAllFilters"
        ><i class="el-icon-delete" /> Effacer tous les filtres</el-button
      >
    </div>
    <!-- Bouton pour télécharger la table en XLSX -->
    <div v-show="itemsTableDownload.length > 0">
      <el-button type="primary" size="medium" plain @click="downloadTable"
        ><i class="el-icon-download" /> XLS
      </el-button>
    </div>
  </div>
</template>

<script>
import { objGetNested, idxToXLSCol } from "@/fonctions";
// export table format Excel :
// https://medium.com/codeptivesolutions/working-with-excel-of-json-data-in-vuejs-using-xlsx-and-bootstrap-vue-9f40fc377d3c
// https://www.npmjs.com/package/xlsx
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      dialogFiltreColOpen: false,
      siteUrl: location.protocol + "//" + location.host,
      colLiendocIdx: [],
    };
  },
  props: {
    createroute: Object,
    showclearallfilters: Boolean,
    tablenom: String,
    ismeddetail: Boolean,
  },
  computed: {
    itemsTable() {
      return this.$store.state.comp.items;
    },
    // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
    colsTable: {
      get() {
        return this.$store.state.comp.cols;
      },
      set: function (value) {
        this.$store.commit("comp/setCols", value);
      },
    },
    nbCols() {
      return this.colsTable.filter((col) => !this.colIsId(col.prop)).length;
    },
    nbColsVisible() {
      return this.colsTable.filter((col) => col.visible == true).length;
    },
    checkAll() {
      return this.nbCols === this.nbColsVisible;
    },
    isIndeterminate() {
      return this.nbCols > this.nbColsVisible && this.nbColsVisible > 0;
    },
    // Données pour le download
    itemsTableDownload() {
      // que les lignes non filtrées
      let items = this.itemsTable.filter((i) => i.visible);
      // que les colonnes non filtrées
      const cols = this.colsTable.filter((c) => c.visible);

      items = items.map((i) => {
        let row = {};
        cols.forEach((c, idx) => {
          let val = objGetNested(i, c.prop.split("."));
          // si colonne true/false remplace par Oui/Non
          if (c.bool) {
            val = val ? "Oui" : "Non";
            // si colonne = url d'un doc
          } else if (c.liendoc) {
            if (val) {
              // ajoute l'url complète
              val = this.siteUrl + c.docfolder + val;
              if (!this.colLiendocIdx.includes(idx)) {
                this.colLiendocIdx.push(idx);
              }
            }
          }
          row[c.label] = val;
        });
        return row;
      });
      return items;
    },
  },
  methods: {
    colIsId(colprop) {
      return colprop == "id" || colprop.startsWith("id_");
    },
    // filtre des colonnes
    handleCheckAllChange(val) {
      this.colsTable.forEach((col) => {
        if (!this.colIsId(col.prop)) {
          col.visible = val;
        }
      });
    },
    // supprime TOUS les filtres sur les lignes (bouton)
    clearAllFilters() {
      this.$store.commit("comp/clearAllFilters");
      this.$store.commit("comp/setItems");
      this.$store.commit("comp/setCols");
    },
    // ouvrir le formulaire pour ajouter un item
    openFormCreate() {
      var routeNom = this.createroute.route;
      var para = this.createroute.params;
      this.$router.push({ name: routeNom, params: para });
    },
    // download le fichier xls de la table
    downloadTable() {
      // crée le nom du fichier
      const tblenom = this.ismeddetail
        ? this.tablenom.replace(/^med/, "")
        : this.tablenom;
      const mednom = this.$store.state.medl.medecin.nom_complet;
      const mednomNorm = this.ismeddetail
        ? "_" +
          mednom
            .replace(/ +/g, "_")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") +
          "_"
        : "_";
      const dateDL = new Date().toLocaleString("sv-SE", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      const xlsNom = "urg-rh_" + tblenom + mednomNorm + dateDL + ".xlsx";
      // met les données dans une feuille xls
      const sheet = XLSX.utils.json_to_sheet(this.itemsTableDownload);
      // ajoute le lien hypertexte aux cellules avec des url du site (docs pdf)
      const XlsCols = this.colLiendocIdx.map((i) => idxToXLSCol(i)); // remplace les inde des cols par leur nom excel (0->A, 1->B, .. , 26->AA, etc.)
      Object.keys(sheet).forEach((k) => {
        if (XlsCols.includes(k.match(/^[A-Z]*/)[0])) {
          const val = objGetNested(sheet[k], ["v"]);
          if (val) {
            sheet[k].l = { Target: val };
          }
        }
      });
      // met la feuille dans un doc xls
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, tblenom);
      // exporte le doc xls
      XLSX.writeFile(wb, xlsNom);
    },
  },
};
</script>
