<template>
  <span>
    <el-popover
      placement="bottom-end"
      width="200px"
      v-model="visible"
      @show="majStoreColsItems"
    >
      <div class="table-filtre">
        <app-fcheck
          v-if="
            ['multichoix', 'checkdoc', 'checkbool'].includes(
              this.col.typefiltre
            )
          "
          :colkey="colkey"
          :colisfiltered="col.isFiltered"
          :coltypefiltre="col.typefiltre"
          :foptions="foptions"
          ref="fcheck"
        />
        <app-fjdatepicker
          v-else-if="col.typefiltre == 'jdate'"
          :colkey="colkey"
          :colisfiltered="col.isFiltered"
          :coltypefiltre="col.typefiltre"
          :foptions="foptions"
          ref="fjdatepicker"
        />
        <app-fmdatepicker
          v-else-if="col.typefiltre == 'mdate'"
          :colkey="colkey"
          :colisfiltered="col.isFiltered"
          :coltypefiltre="col.typefiltre"
          :foptions="foptions"
          ref="fmdatepicker"
        />
        <app-fautocompl
          v-else-if="col.typefiltre == 'autocompl'"
          :colkey="colkey"
          :colisfiltered="col.isFiltered"
          :coltypefiltre="col.typefiltre"
          :foptions="foptions"
          ref="fautocompl"
        />
      </div>
      <div class="table-filtre-bottom">
        <hr />
        <el-button
          size="mini"
          @click="applique"
          :disabled="
            ['jdate', 'mdate', 'autocompl'].includes(col.typefiltre) &&
            col.isFiltered
          "
          >Appliquer</el-button
        >
        <el-button size="mini" @click="efface">Effacer</el-button>
      </div>
      <i slot="reference" class="el-icon-arrow-down el-icon--right"></i>
    </el-popover>
  </span>
</template>

<script>
import { colFilterOpts } from "@/fonctions";
import AppFcheck from "@/components/rh/ui/table/AppFcheck";
import AppFjdatepicker from "@/components/rh/ui/table/AppFjdatepicker";
import AppFmdatepicker from "@/components/rh/ui/table/AppFmdatepicker";
import AppFautocompl from "@/components/rh/ui/table/AppFautocompl";

export default {
  components: {
    "app-fcheck": AppFcheck,
    "app-fjdatepicker": AppFjdatepicker,
    "app-fmdatepicker": AppFmdatepicker,
    "app-fautocompl": AppFautocompl,
  },
  data() {
    return {
      visible: false,
    };
  },
  props: {
    colkey: String,
  },
  computed: {
    col() {
      // récupére les propriétés de la colonne
      return this.$store.state.comp.cols.filter(
        (c) => c.prop == this.colkey
      )[0];
    },
    foptions() {
      return colFilterOpts(this.col);
    },
  },
  methods: {
    applique() {
      if (
        ["multichoix", "checkdoc", "checkbool"].includes(this.col.typefiltre)
      ) {
        this.$refs.fcheck.getCheckFiltre();
      } else if (this.col.typefiltre == "jdate") {
        this.$refs.fjdatepicker.getDateFiltre();
      } else if (this.col.typefiltre == "mdate") {
        this.$refs.fmdatepicker.getDateFiltre();
      } else if (this.col.typefiltre == "autocompl") {
        this.$refs.fautocompl.getTextFiltre();
      }

      this.majStoreColsItems();

      //cache la boîte de dialogue
      this.visible = false;

      // si le filtre ne retourne aucune ligne:
      if (this.$store.getters["comp/itemsVisibleNbre"] == 0) {
        // message
        this.$alert(
          "Aucun élément ne correspond aux critères du filtre",
          this.col.label,
          {
            confirmButtonText: "OK",
          }
        );
        // le filtre est effacé
        this.efface();
      }
    },
    efface() {
      if (
        ["multichoix", "checkdoc", "checkbool"].includes(this.col.typefiltre)
      ) {
        this.$refs.fcheck.clearCheckFiltre();
      } else if (this.col.typefiltre == "jdate") {
        this.$refs.fjdatepicker.clearDateFiltre();
      } else if (this.col.typefiltre == "mdate") {
        this.$refs.fmdatepicker.clearDateFiltre();
      } else if (this.col.typefiltre == "autocompl") {
        this.$refs.fautocompl.clearTextFiltre();
      }
      this.visible = false;
      this.majStoreColsItems();
    },
    majStoreColsItems() {
      this.$store.commit("comp/setItems");
      this.$store.commit("comp/setCols");
    },
  },
};
</script>
